export const config = {
    ws: {
        host: 'wss://stream.devamourlee.com/'
    },
    wsNotify: {
        host: 'wss://stream.devamourlee.com/'
    },
    domain: {
        imgDomain: 'https://img.luxee.date'
    }
};